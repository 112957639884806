<template>
  <div class="component-wrapper">
    <b-card>
      <div class="d-flex">
        <div v-if="loader.agent" class="d-flex">
          <b-skeleton type="button" variant="danger" width="150px"></b-skeleton>
          <b-skeleton type="button" variant="danger" width="150px" class="ml-1"></b-skeleton>
          <b-skeleton type="button" variant="danger" width="150px" class="ml-1"></b-skeleton>
        </div>
        <b-button v-for="x in parameter.agentList" :key="x.code" :variant="parameter.selectedAgent == x ? `danger` : `outline-danger`" class="mr-1" @click="agentChanged(x)">
          {{ x.description }}
        </b-button>
        <b-button class="ml-auto" variant="primary" @click.stop="showDetail()">
          <b-icon icon="plus-circle" scale="1"></b-icon>
          New Bait
        </b-button>
      </div>
    </b-card>
    <b-card>
      <template #default>
        <vue-element-loading :active="loader.table"></vue-element-loading>
        <b-table
          :items="table.items"
          :fields="table.fields"
          :current-page="table.paging.currentPage"
          :per-page="table.paging.perPage"
          :total-rows="table.items.length"
          :busy="loader.table"
          small
          fixed
          striped
        >
          <template #cell(recordDate)="data">
            <div>{{ data.item.recordDate | date }}</div>
          </template>
          <template #cell(action)="data">
            <b-button size="sm" variant="warning" @click.stop="showDetail(data.item)">Edit</b-button>
            <b-button class="ml-1" size="sm" variant="danger" @click.stop="remove(data.item)">Delete</b-button>
          </template>
        </b-table>
      </template>

      <template #footer>
        <div class="d-flex mt-auto align-items-center">
          <page-info :page-no="table.paging.pageNo" :page-size="table.paging.pageSize" :total="table.items.length" />
          <b-pagination v-model="table.paging.pageNo" :totalRows="table.items.length" :per-page="table.paging.pageSize" align="center" class="ml-auto"></b-pagination>
        </div>
      </template>
    </b-card>

    <b-modal v-model="modal.detail" size="xl" lazy hide-footer no-close-on-backdrop no-close-on-esc scrollable @hide="selectedRecord = null">
      <bait-detail :bait="selectedRecord" :agent="parameter.selectedAgent" @onSaveSuccess="saveSuccess"></bait-detail>
    </b-modal>
  </div>
</template>

<script>
import api from "@api";
import BaitDetail from "./Detail.vue";

export default {
  name: `BaitList`,
  components: { BaitDetail },
  data() {
    return {
      parameter: {
        agentList: [],
        selectedAgent: null,
      },

      selectedRecord: null,
      table: {
        items: [],
        fields: [
          { key: "recordDate", label: "Record Date", sortKey: "recordDate", sortable: true },
          { key: "brand", label: "Brand", sortable: true },
          { key: "targetUrl", label: "Target URL", sortable: true },
          { key: "action", label: "", class: "text-right" },
        ],
        paging: {
          pageNo: 1,
          pageSize: 10,
        },
      },
      loader: { table: false, agent: false },
      modal: { detail: false },
    };
  },
  computed: {},
  async mounted() {
    await this.loadAgent();
  },
  methods: {
    async loadAgent() {
      this.loader.agent = true;
      try {
        this.parameter.agentList = await api.get(`agent`);
        this.parameter.selectedAgent = this.parameter.agentList[0];
        this.loadTable();
      } catch (error) {
        this.showToast(`error`, error);
      } finally {
        this.loader.agent = false;
      }
    },
    agentChanged(agent) {
      this.parameter.selectedAgent = agent;
      this.loadTable();
    },
    async loadTable() {
      this.loader.table = true;
      try {
        this.table.items = await api.get(`bait/agent/${this.parameter.selectedAgent.code}`);
      } catch (error) {
        this.showToast(`error`, error);
      } finally {
        this.loader.table = false;
      }
    },
    async remove(rec) {
      let confirmed = await this.showConfirm(`Are you sure you want to delete this record?`);
      if (!confirmed) return;

      try {
        await api.delete(`bait/${rec.id}`);
        this.showToast(`success`, `Record has been deleted`);
        await this.loadTable();
      } catch (error) {
        this.showToast(`error`, error);
      }
    },
    async showDetail(rec) {
      this.selectedRecord = rec;
      this.modal.detail = true;
    },
    saveSuccess() {
      this.modal.detail = false;
      this.loadTable();
    },
    cancel() {
      this.modal.detail = false;
    },
  },
};
</script>
