<template>
    <div class="component-wrapper">
        <vue-element-loading :active="loader" is-full-screen></vue-element-loading>
        <b-tabs v-model="tabIndex" lazy>
            <b-tab title="General" lazy>
                <b-card>
                    <b-row>
                        <b-col cols="4">
                            <b-form-group label="Brand Name *">
                                <b-form-input v-model="payload.brand" :state="!!payload.brand"
                                    placeholder="Enter Brand Name" trim></b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col cols="4">

                            <b-form-group label="Target URL *">
                                <b-form-input v-model="payload.targetUrl"
                                    placeholder="Target URL for Anchor ex: https://mymoneysite.com" trim :debounce="500"
                                    :state="urlCorrect">
                                </b-form-input>
                            </b-form-group>
                        </b-col>
                    </b-row>

                    <template #footer>
                        <div class="d-flex">
                            <b-button variant="primary" class="ml-auto" @click.stop="tabIndex++">Next</b-button>
                        </div>
                    </template>
                </b-card>
            </b-tab>

            <b-tab title="Domain" lazy>
                <b-card>
                    <div class="d-flex">
                        <div>
                            <span>Selected Domain Count : {{ table.domain.selectedRows.length | number }}</span>
                        </div>
                        <b-button variant="danger" class="ml-auto" @click.stop="clearDomain">
                            Clear Selected Domain
                        </b-button>
                    </div>
                </b-card>
                <b-card>
                    <b-form-group label="Filter Domain" label-cols="3">
                        <b-input-group>
                            <b-input v-model="table.domain.filter" debounce="300"></b-input>
                            <b-input-group-append>
                                <b-button variant="primary" @click.stop="modal.domainFilter = true">
                                    Filter Multiple
                                </b-button>
                                <b-button variant="danger" @click.stop="clearDomainFilter">
                                    Clear Filter
                                </b-button>
                            </b-input-group-append>
                        </b-input-group>

                    </b-form-group>

                    <b-table :items="filteredDomain" :fields="table.domain.fields" small striped selectable hover>
                        <template #cell(select)="data">
                            <b-form-checkbox v-model="table.domain.selectedRows" :value="data.item.domainUrl">
                            </b-form-checkbox>
                        </template>
                    </b-table>
                    <template #footer>
                        <div class="d-flex">
                            <b-button variant="primary" @click.stop="tabIndex--">Prev</b-button>
                            <b-button variant="primary" class="ml-auto" @click.stop="tabIndex++">Next</b-button>
                        </div>
                    </template>
                </b-card>
            </b-tab>

            <b-tab title="Order Detail" lazy>
                <b-card>
                    <b-row>
                        <b-col>
                            <b-form-group label="Keyword">
                                <b-input-group>
                                    <b-form-input v-model="keyword" placeholder="Whats The Keyword ?"> </b-form-input>
                                    <b-form-input v-model="keywordCount" type="number" placeholder="How Many Keywords ?"
                                        trim></b-form-input>
                                    <b-input-group-append>
                                        <b-button variant="primary" :disabled="!keywordCount || !keyword"
                                            @click.stop="addKw">Add
                                            Keywords</b-button>
                                        <b-button variant="danger" @click.stop="clearKw">Clear</b-button>
                                    </b-input-group-append>
                                </b-input-group>
                            </b-form-group>
                        </b-col>
                    </b-row>
                </b-card>
                <b-card>
                    <template #header>
                        <h3 class="mx-auto">Order Detail</h3>
                    </template>
                    <b-table :items="table.baitDetail.items" :fields="table.baitDetail.field" small striped hover
                        show-empty>
                        <template #cell(seq)="data">{{ data.index + 1 }}</template>
                        <template #cell(anchor)="data">
                            <b-input v-model="data.item.anchor" placeholder="Insert Anchor" trim></b-input>
                        </template>
                    </b-table>
                    <template #footer>
                        <b-button variant="success" size="lg" block @click.stop="save">SUBMIT ORDER</b-button>
                    </template>
                </b-card>
            </b-tab>
        </b-tabs>

        <b-modal v-model="modal.domainFilter" centered hide-footer>
            <b-form-textarea v-model="table.domain.filterMultipleText"
                placeholder="Input domain here .. 1 domain per row" trim rows="10" max-rows="15"></b-form-textarea>

            <hr />

            <div class="d-flex">
                <b-button variant="primary" class="ml-auto" @click.stop="filterMultipleDomain">Submit</b-button>
            </div>
        </b-modal>
    </div>
</template>

<script>
import api from "@api";

export default {
    name: `BaitDetail`,
    props: {
        bait: {
            type: Object,
            required: false
        },
        agent: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            parameter: {
                domainList: [],
            },
            payload: {
                brand: null,
                targetUrl: null,
                baitDetail: []
            },
            keyword: null,
            keywordCount: 0,
            table: {
                domain: {
                    items: [],
                    fields: [
                        { key: `select`, label: ``, thStyle: `width: 40px` },
                        { key: `domainUrl`, label: `Domain`, sortable: true },
                    ],
                    filter: null,
                    filterMultipleText: null,
                    filterMultipleArray: [],
                    selectedRows: [],
                },
                baitDetail: {
                    items: [],
                    fields: [
                        { key: "seq", label: "#" },
                        { key: "domain", label: "Domain" },
                        { key: "anchor", label: "Anchor" },
                    ],
                },
            },
            tabIndex: 0,
            loader: false,
            modal: { domainFilter: false }
        }
    },
    computed: {
        filteredDomain() {
            let retVal = this.table.domain.items;

            if (!!this.table.domain.filter) retVal = this.table.domain.items.filter(x => x.domainUrl.includes(this.table.domain.filter));
            if (this.table.domain.filterMultipleArray.length > 0) retVal = this.table.domain.items.filter(x => this.table.domain.filterMultipleArray.includes(x.domainUrl));

            return retVal;
        },
        urlCorrect() {
            if (!this.payload.targetUrl) return false;
            const urlRegex = /^(https?:\/\/)?([\w.-]+)\.([a-z]{2,})(\/\S*)?$/i;
            if (!urlRegex.test(this.payload.targetUrl)) {
                return false;
            }
            return true;
        }
    },
    watch: {
        "table.domain.selectedRows": function (newVal, oldVal) {
            this.table.baitDetail.items = newVal.map(x => ({ domain: x, anchor: null }));
            this.table.baitDetail.items.forEach(item => {
                const payloadItem = this.payload.baitDetail.find(payloadItem => payloadItem.domain === item.domain);
                if (payloadItem) {
                    item.anchor = payloadItem.anchor;
                }
            });
        },
    },
    async mounted() {
        await this.loadDomain();
        if (this.bait) await this.loadDetail();
    },
    methods: {
        async loadDetail() {
            this.loader = true;
            try {
                this.payload = await api.get(`bait/${this.bait.id}`);
                this.table.domain.selectedRows = this.payload.baitDetail.map(x => x.domain);
            } catch (error) {
                this.showToast(`error`, error);
            } finally {
                this.loader = false;
            }
        },
        async save() {
            this.loader = true;
            this.payload.baitDetail = this.table.baitDetail.items.map(x => ({ domain: x.domain, anchor: x.anchor }));
            this.payload.agentCode = this.agent.code;
            try {
                if (this.bait) {
                    await api.put(`bait/${this.bait.id}`, this.payload);
                } else {
                    await api.post(`bait`, this.payload);
                }
                this.$emit(`onSaveSuccess`)
            } catch (error) {
                this.showToast(`error`, error);
            } finally {
                this.loader = false;
            }
        },
        clearKw() {
            this.keyword = null;
            this.keywordCount = 0;
        },
        async loadDomain() {
            try {
                this.table.domain.items = await api.get(`domain`, { agentCode: this.agent.code, onlyValid: true });
            } catch (error) {
                this.showToast(`error`, error);
            }
        },
        clearDomain() {
            this.table.domain.selectedRows = [];
        },
        addKw() {
            let count = 0;
            const itemsLength = this.table.baitDetail.items.length;
            for (let i = 0; i < itemsLength; i++) {
                let x = this.table.baitDetail.items[i];
                if (!x.anchor) {
                    x.anchor = this.keyword;
                    count++;
                }
                if (count >= this.keywordCount) break;
            }
            this.keyword = null;
            this.keywordCount = null;
        },
        filterMultipleDomain() {
            this.table.domain.filter = null;
            this.table.domain.filterMultipleArray = this.table.domain.filterMultipleText.split("\n").map(x => x.trim()).filter(x => x !== "");
            this.modal.domainFilter = false;
        },
        clearDomainFilter() {
            this.table.domain.filterMultipleArray = [];
            this.table.domain.filterMultipleText = null;
            this.table.domain.filter = null;
        },
    }
}
</script>